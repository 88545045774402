@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

.page_pdf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.page, iframe {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

iframe, #adobe-dc-view {
  width: 100vw;     /* 100% of the viewport width */
  height: 100vh;    /* 100% of the viewport height */
  border: none;     /* Optional: removes the border around the iframe */
}

nav {
  display: flex;
  margin: 1rem auto;
  align-items: center;
  justify-content: space-between;
}

button {
  background-color: #f5f5f5;
  border: none;
  color: #000;
  padding: 1rem;
  list-style: none;
  margin: 0.7rem;
  cursor: pointer;
}

.previous {
  background-color: #4a8fed;
  color: black;
}

.next {
  background-color: #4a8fed;
  color: white;
}

.default {
  background-color: #4a8fed;
  color: white;
}

p {
  background-color: #f5f5f5;
  padding: 1rem;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  border: 1px solid #ddd;
  padding: 8px;
}

table th {
  background-color: #f2f2f2;
}

table tr:first-child th, table tr:first-child td {
  border-top: 1px solid #ddd;
}

table tr th:first-child, table tr td:first-child {
  border-left: 1px solid #ddd;
}
